<template>
  <b-sidebar

      id="add-new-user-sidebar"
      :visible="isAddLeavesSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-add-leaves-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
          Add Leave
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <b-form
            class="p-3"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

              <label class="font-weight-700 ">Leave Type</label>
              <div class="demo-inline-spacing ">

                <b-form-radio
                    v-model="form.leave_type"
                    class="custom-control-primary mt-1"
                    name="some-radio9"
                    value="time"
                >
                  Time Off
                  <feather-icon class="text-primary ml-50" icon="InfoIcon"
                                v-b-tooltip.hover.top="'The clinician is unavailable for specific hours '"
                                size="12"/>
                </b-form-radio>
                <b-form-radio
                    v-model="form.leave_type"
                    class="custom-control-primary mt-1 "
                    name="some-radio9"
                    value="day"
                >
                  Day Off
                  <feather-icon class="text-primary ml-50" icon="InfoIcon"
                                v-b-tooltip.hover.top="'The clinician is unavailable for more than one full day'"
                                size="12"/>
                </b-form-radio>
              </div>


            <div  v-if="form.leave_type === 'time'">

<!--                        <vue-perfect-scrollbar
              :settings="perfectScrollbarSettings"
              class="todo-task-list-wrapper list-group scroll-area max-vh-50"
              style="max-height: 60vh;"
          >-->
              <b-row class="mt-2 "
                     v-for="(item, index) in form_reapeater_row"
                     :id="item.id"
                     :key="item.id">
                <b-col md="6">
                  <validation-provider
                      #default="validationContext"
                      name="Date"
                      rules="required"
                  >
                    <b-form-group
                        label="Date"
                        label-for="date"
                    >
                      <flat-pickr
                          v-model="start_date"
                          class="form-control" placeholder="Select"
                      />

<!--                      <b-form-datepicker
                          id="date"
                          v-model="start_date"
                          :state="getValidationState(validationContext)"
                          class="mb-1"
                          placeholder="Select"
                          trim
                      />-->

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <validation-provider
                      #default="validationContext"
                      name="Start Date"
                      rules="required"
                  >
                    <b-form-group
                        label="Start Date"
                        label-for="start_date"
                    >
                      <b-form-timepicker
                          id="start_date"
                          v-model="start_date"
                          :state="getValidationState(validationContext)"
                          class="mb-1"
                          placeholder="Select"
                          trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <validation-provider
                      #default="validationContext"
                      name="End Date"
                      rules="required"
                  >
                    <b-form-group
                        label="End Date"
                        label-for="start_date"
                    >
                      <b-form-timepicker
                          id="end_date"
                          v-model="start_date"
                          :state="getValidationState(validationContext)"
                          class="mb-1"
                          placeholder="Select"
                          trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
<!--                                    </vue-perfect-scrollbar>-->
              <b-row>
                <b-col cols="12">

                  <b-button
                      variant="link"
                      @click="repeatAgain"
                  >
                    <feather-icon
                        class="mr-50"
                        icon="PlusIcon"
                    />
                    <span class="align-middle"> Add Date</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>

          <div v-else>
            <b-row class="mt-2 ">
              <b-col md="6">
                <validation-provider
                    #default="validationContext"
                    name="Date"
                    rules="required"
                >
                  <b-form-group
                      label="Date"
                      label-for="date"
                  >
                    <flat-pickr
                        v-model="start_date"
                        class="form-control" placeholder="Select"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                    #default="validationContext"
                    name="Date"
                    rules="required"
                >
                  <b-form-group
                      label="Date"
                      label-for="date"
                  >
                    <flat-pickr
                        v-model="start_date"
                        class="form-control" placeholder="Select"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </div>


            <b-form-group >
              <label class="font-weight-bold  mt-1">Reason</label>
              <v-select
                  v-model="reason"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="reasons"
                  label="title"
                  placeholder="Select"
              />
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-3 pl-1">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
            >
              Save
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1"
                type="button"
                variant="outline-white"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
  BFormTimepicker,
  BAlert,
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BInputGroup,
  BRow,
  BSidebar,
  VBTooltip,
} from 'bootstrap-vue'

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {alphaNum, email, required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {

  methods: {
    repeatAgain() {
      this.form_reapeater_row.push({
        id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      this.form_reapeater_row.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
  },
  components: {
    BFormTimepicker,
    flatPickr,
    BRow,
    BCol,
    BFormRadio,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isAddLeavesSidebarActive',
    event: 'update:is-add-leaves-sidebar-active'
  },
  props: {
    isAddLeavesSidebarActive: {
      type: Boolean,
      required: true
    },
    roleOptions: {
      type: Array,
      required: false
    },
    planOptions: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      form_reapeater_row: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      reasons: [],
      reason: null,
      form: {
        leave_type: 'time'
      },
      required,
      alphaNum,
      email,
      start_date: '',
      end_date: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 100
      }
    }
  },
  setup(props, {emit}) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: ''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-add-leaves-sidebar-active', false)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>



