<template>

  <div>
    <b-card no-body>

      <b-card-header class="border-bottom pb-1">
        <div class="d-flex flex-row">

          <b-avatar
              rounded
              variant="light-primary"
          >
            <feather-icon
                class="text-dark font-weight-bolder"
                icon="CalendarIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1" style="padding-top:8px">
            <h4 class=" font-weight-700">
              Shifts
            </h4>
          </div>

        </div>
      </b-card-header>
      <b-card-body class=" pl-1 px-0 mx-0">

        <vue-perfect-scrollbar
            class="todo-task-list-wrapper list-group scroll-area min-vh-50 "
            style="max-height: 315px"
        >

          <div v-for="index in [1,2,3,4,5,6,7,8,9,10]" class="d-flex flex-row my-1 border-bottom">
            <div class="pl-1 ">
              <h5 class="font-weight-700">Tottenham Hale Medical Practice
                <b-badge
                    :variant="'light-danger'"
                    class="text-capitalize  mr-2"
                >
                  COVID-19
                </b-badge>
              </h5>
              <span class="mr-1">
                    <feather-icon icon="CalendarIcon"/>
                    Apr 15, 2021 - Jun 15, 2021
        </span>
              <span class="mr-1">
                    <feather-icon icon="DatabaseIcon"/>
                    £750,000
        </span>
            </div>
            <div class="ml-auto ">
              <b-badge
                  :variant="'light-success'"
                  class="text-capitalize mt-1 mr-1"
              >
                Active
              </b-badge>
              <ProfileShiftsDropDownMenu/>

            </div>
          </div>

        </vue-perfect-scrollbar>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {BAvatar, BBadge, BCard, BCardBody, BCardHeader, BCol, BImg, BMedia, BRow} from 'bootstrap-vue'
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ProfileShiftsDropDownMenu from "@/views/profile/includes/dropdowns/ProfileShiftsDropDownMenu";

export default {
  components: {
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    ProfileShiftsDropDownMenu
  }
}
</script>
